<template>
  <PageContainer>
    <template v-slot:header>
      <v-col class="d-flex">
        <v-btn icon size="40px" @click="goBack">
          <v-icon color="primaryblack2" size="24px">mdi-chevron-left</v-icon>
        </v-btn>
        <span class="heading-2"> {{ form.id ? "Update" : "Add" }} healthcare service </span>
      </v-col>
    </template>
    <v-card :loading="isLoading" class="organization-form">
      <v-col>
        <span class="heading-4 text-left">{{ organization.name }} healtcare service</span>
      </v-col>
      <v-divider />

      <v-col class="">
        <BaseInput
          :fullWidth="true"
          :labelActive="true"
          :value="form.name"
          class="my-2 mb17"
          fieldName="name"
          outlined
          placeholder="Description of service"
          required
          @change="changeFormField"
        />
        <Select
          :items="serviceCategories"
          :label-active="true"
          :multiple="true"
          :value="form.categoryCodingCodes"
          field-name="categoryCodingCodes"
          item-text="display"
          item-value="code"
          label="Broad category of service being performed"
          @change="changeFormField"
        />
        <Select
          :items="serviceTypes"
          :label-active="true"
          :multiple="true"
          :value="form.typeCodingCodes"
          field-name="typeCodingCodes"
          item-text="display"
          item-value="code"
          label="Type of service that may be delivered"
          @change="changeFormField"
        />
        <Select
          :items="practiceCodes"
          :label-active="true"
          :multiple="true"
          :value="form.specialtyCodingCodes"
          field-name="specialtyCodingCodes"
          item-text="display"
          item-value="code"
          label="Specialties handled by the HealthcareService"
          @change="changeFormField"
        />
      </v-col>
      <v-divider />
      <v-card-actions class="d-flex justify-end">
        <PrimaryButton :loading="isSaving" text="Save" @click="performSave" />
      </v-card-actions>
    </v-card>
  </PageContainer>
</template>
<script>
import { mapActions, mapState } from "pinia";

import { HealthcareServiceApi } from "@/api/healthcareService";
import BaseInput from "@/components/uikit/BaseInput.vue";
import PageContainer from "@/components/uikit/PageContainer.vue";
import PrimaryButton from "@/components/uikit/PrimaryButton.vue";
import Select from "@/components/uikit/Select.vue";
import { useHealthcareServiceStore } from "@/pinia-store/healthcareServices";
import { useOrganizationStore } from "@/pinia-store/organization";
import { useTerminologyStore } from "@/pinia-store/terminology";
import { routesEnum } from "@/types/Routes.enum";

export default {
  name: "OrganizationEdit",
  data() {
    return {
      isLoading: false,
    };
  },
  components: { PrimaryButton, BaseInput, PageContainer, Select },
  computed: {
    ...mapState(useTerminologyStore, ["serviceCategories", "practiceCodes", "serviceTypes"]),
    ...mapState(useOrganizationStore, ["organization"]),
    ...mapState(useHealthcareServiceStore, ["form", "isSaving"]),
  },
  methods: {
    ...mapActions(useTerminologyStore, ["preloadServiceCategories", "preloadPracticeCodes", "preloadServiceTypes"]),
    ...mapActions(useOrganizationStore, ["getOrganization"]),
    ...mapActions(useHealthcareServiceStore, ["changeFormField", "saveForm", "setForm"]),
    async performSave() {
      this.changeFormField(this.$route.params.organizationId, "providedByOrganizationId");
      await this.saveForm();
    },
    goBack() {
      this.$router.push({
        name: routesEnum.organizationHealthcareServices,
        organizationId: this.$route.params.organizationId,
      });
    },
  },
  async mounted() {
    await Promise.all([this.preloadServiceCategories(), this.preloadPracticeCodes(), this.preloadServiceTypes()]);
    if (this.$route.params.organizationId) {
      this.isLoading = true;
      this.getOrganization(this.$route.params.organizationId);
      this.isLoading = false;
    }
    if (this.$route.params.id) {
      this.isLoading = true;
      const item = await HealthcareServiceApi.find(this.$route.params.id);
      this.setForm(item);
      this.isLoading = false;
    }
    console.log("Organization mounted");
  },
};
</script>

<style lang="scss" scoped>
.organization-form {
  max-width: 450px;
  margin: 0 auto;
  margin-top: 30px;
  width: 90%;
}
</style>
